import { render, staticRenderFns } from "./LoginDialog2.vue?vue&type=template&id=4af58a42&scoped=true&"
import script from "./LoginDialog2.vue?vue&type=script&lang=js&"
export * from "./LoginDialog2.vue?vue&type=script&lang=js&"
import style0 from "./LoginDialog2.vue?vue&type=style&index=0&id=4af58a42&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af58a42",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tabs: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/Design/Tabs.vue').default,Input: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/From/input.vue').default,Select: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/From/select.vue').default,RegisterDialog: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/RegisterDialog.vue').default})
