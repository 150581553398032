//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tabs from "@/components/Design/Tabs.vue";
import { areaInfo } from "~/static/js/areaInfo";
import { mapActions, mapMutations } from "vuex";
import { loadJsAsync } from "~~/utils";
export default {
  props: {
    // 父辈向子辈传参
  },
  comments: {},
  data() {
    return {
      tabsItem: [
        { title: "email", index: 0 },
        { title: "phone number", index: 1 },
      ],
      email: "",
      password: "",
      phone: "",
      code: "",
      selectIndex: 0,
      identify_code: "",
      isCurrent: true,
      isCurrent_phone: false,
      areaInfoArr: [],
      isSend: false,
      num: 60,
      isShowDialog: false,
      showLoginTab: true,
    };
  },
  asyncData() {
    // let area_Code= await this.$()
  },

  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
    Tabs,
  },
  methods: {
    ...mapActions("user", ["thridLoginAction", "initThridLogin", "getUserInfoAll"]),
    ...mapMutations("user", ["setIsLogin", "setUserInfo"]),
    // 改变弹窗
    changeDialog() {
      this.isShowDialog = !this.isShowDialog;
    },
    //第三方登陆
    thridLogin(type) {
      console.log("点击了吗");
      //派发action
      // this.thridLoginAction({ type });
      this.$nuxt.$store.dispatch("user/thridLoginAction", { type });
    },
    //初始化
    async initScirpt() {
      console.log("挂载sdk");
      await loadJsAsync("https://connect.facebook.net/en_US/sdk.js", true, { id: "facebookScriptId", crossorigin: "anonymous" });
      await loadJsAsync("https://accounts.google.com/gsi/client", true, { id: "googleClientScriptId" });
      setTimeout(() => {
        // this.initThridLogin();
        this.$nuxt.$store.dispatch("user/initThridLogin");
      }, 100);
    },

    /**
     * @description:登陆提交表单
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-16 10:07:52
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    async loginSubmit() {
      if (this.isCurrent) {
        //邮箱登陆
        const success = await this.$validator.validateAll(["email", "password"]);
        if (success) {
          let params = {
            email: this.email,
            phone: this.phone,
            password: this.password,
          };
          let res = await this.$nuxt.$submitLogin(params, "/api/auth/login");
          console.log(res);
          if (res.code == 200000) {
            try {
              this.$nuxt.$cookies.set("userInfo", res.data, {
                maxAge: 60 * 60 * 24 * 120,
                path: "/",
              });
              console.log(this.$nuxt);
              this.$nuxt.$store.commit("user/setIsLogin", true);

              // if (this.fasterMethod) {
              //   this.fasterMethod();
              // }
              this.setUserInfo(res.data.user);
              // this.$nuxt.$router.push({
              //   path: "/user",
              // });
              this.changeDialog();
              this.$emit("loginSuccessHanlder");
            } catch (error) {
              console.log("邮箱登陆报错" + error);
            }
          }
        }
      } else {
        // 电话登陆
        console.log("电话登陆");
        let result = await this.$validator.validateAll(["phone", "identify_code", "code"]);
        if (result) {
          let params = {
            phone: this.phone,
            code: this.code,
            identify_code: this.identify_code,
          };
          try {
            let res = await this.$nuxt.$submitLogin(params, "/api/auth/login/phone");
            if (res.code == 200000) {
              this.$cookies.set("userInfo", res.data, {
                maxAge: 60 * 60 * 24 * 120,
                path: "/",
              });
              this.getUserInfoAll();
              this.$nuxt.$store.commit("user/setIsLogin", true);
              // this.setUserInfo(res.data);
              // this.$nuxt.$router.push({
              //   path: "/user",
              // });
              this.changeDialog();
              this.$emit("loginSuccessHanlder");
            }
          } catch (error) {
            console.log("电话登陆报错" + error);
          }
        }
        console.log(result);
      }
    },
    /**
     * @description: 点击切换不同的内容
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-16 09:57:29
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     * @param {*} item
     */
    handler(item) {
      // if (this.selectIndex != item.index) {
      //   this.selectIndex = item.index;
      // }
      console.log(item);
      if (this.isCurrent) {
        this.isCurrent = false;
        this.isCurrent_phone = true;
      } else {
        this.isCurrent = true;
        this.isCurrent_phone = false;
      }
    },
    /**
     * @description:  获取验证码
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-16 09:54:26
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * escription: Do not Description
     */
    async getCode() {
      if (this.isSend) return;
      let result = await this.$validator.validate("phone");
      console.log(result);
      if (result) {
        this.isSend = true;
        this.countDown();
        let params = {
          phone: this.phone,
          code: this.code,
          identify_code: this.identify_code,
        };

        let res = await this.$nuxt.$getCode(params);
        console.log(res);
      }
    },

    /**
     * @description: 倒计时
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-16 09:51:16
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    countDown() {
      let _coutdown = setInterval(() => {
        if (this.num == 0) {
          this.isSend = false;
          clearInterval(_coutdown);
        } else {
          --this.num;
        }
      }, 1000);
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-30 14:40:03
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 注册成功之后的回调
     * @return {*}
     * @Description: Do not Description
     */
    registerSuccessHanlder(email, password) {
      this.isCurrent = true;
      // this.loginSubmit();
      this.email = email;
      this.password = password;
      this.loginSubmit();
    },
  },
  mounted() {
    // 页面进入时加载内容
    console.log(areaInfo);
    this.areaInfoArr = areaInfo;
    // 挂载第三方登陆sdk
    this.initScirpt();
    // console.log(this);
  },
  watch: {
    // 监测变化
  },
};
