import { render, staticRenderFns } from "./RegisterDialog.vue?vue&type=template&id=71cea106&scoped=true&"
import script from "./RegisterDialog.vue?vue&type=script&lang=js&"
export * from "./RegisterDialog.vue?vue&type=script&lang=js&"
import style0 from "./RegisterDialog.vue?vue&type=style&index=0&id=71cea106&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cea106",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/From/input.vue').default})
