//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    tabsItem: {
      type: Array,
      require: true,
      default: () => {
        return [];
      },
    },
    iscustom: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    selectIndex: {
      type: Number,
      default: () => 0,
    },
    isFixed: {
      type: Boolean,
      default: () => false,
    },
  },
  name: "Tabs",
  data() {
    return {
      selectNum: 0,
    };
  },
  created() {
    // 实例被创建之后执行代码
    this.selectNum = this.selectIndex;
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    tabsChange(item) {
      if (this.selectNum != item.index) {
        this.selectNum = item.index;
        this.$emit("handlerTabs", item);
        this.scrollToHandler();
      }
    },
    scrollToHandler() {
      const { nav } = this.$refs;
      const { titles } = this.$refs;
      const _title = titles[this.selectIndex];

      const _width = nav.offsetWidth; //总宽
      const _swidth = nav.scrollWidth;

      if (_swidth > _width) {
        //溢出了，需要滚动
        let ww = _title.offsetWidth; //盒子的宽
        let lf = _title.offsetLeft; //元素所在的位置
        nav.scrollLeft = lf - (_width - ww) / 2;
        console.log(lf - (_width - ww) / 2);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.scrollToHandler();
  },
  watch: {
    // 监测变化
  },
};
